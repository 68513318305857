<template>
  <div id="swagger">
  </div>
</template>

<script>
import SwaggerUI from 'swagger-ui';
import 'swagger-ui/dist/swagger-ui.css';
import {Action, Getter} from '@/store/openapi/types'

export default {
  name: "Swagger",
  data: () => ({
    ui: {},
  }),
  watch: {
    apiSpec: function(val) {
      this.ui = SwaggerUI({
        spec: val,
        dom_id: '#swagger',
      });
    }
  },
  computed: {
    apiSpec() {
      return this.$store.getters[`${[Getter.GetApiSpec]}`]
    },
  },
  mounted() {
    this.$store.dispatch(`${[Action.ApiSpec]}`)
  },
}
</script>

<style scoped>

</style>
